import React, { useContext } from 'react'
import { gql } from '@apollo/client'
import { RelatedArticlesHomeFragment } from './__generated__/relatedArticles'
import { StyledHeading } from '../styledHeading'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { truncate } from '../../../utils/truncate'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { GlobalContext } from '../../../globals/context'

export type RelatedArticlesProps = {
  data: RelatedArticlesHomeFragment[]
  heading?: string | undefined
}

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({ data, heading }) => {
  const { fallback } = useContext(GlobalContext)

  if (fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }
  
  const { flexibleContentImageFallback, newsFallbackImage, productDetailImageFallback, productListingImageFallback } = fallback?.globalSet

  return (
    <>
      <StyledHeading variant="h5" align="center">
        {heading}
      </StyledHeading>
      <CardDesignCarousel>
        {data.map((item, index) => {
          let image
          let excerpt
          let title
          let uri
          let postDate

          switch (item.__typename) {
          case 'news_newsEntryType_Entry':
            image = item?.newsDetailBanner?.[0]?.bannerImage?.[0] || newsFallbackImage?.[0]
            title = item?.title
            excerpt = item?.newsArticleDescription
            uri = item?.uri
            postDate = item?.postDate
            break
          case 'newsLandingPage_newsLandingPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            postDate = item?.postDate
            break

          case 'products_pages_product_detail_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] || productDetailImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            postDate = item?.postDate
            break

          case 'products_pages_product_fcp_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] || flexibleContentImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            postDate = item?.postDate
            break

          case 'products_pages_product_listing_Entry':
            image = item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0]?.url ? item?.secondaryPageBanner?.[0]?.secondaryBannerImage?.[0] : productListingImageFallback?.[0]
            title = item?.title
            excerpt = item?.metaExcerpt
            uri = item?.uri
            break

          case 'faqLandingPage_faqLandingPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            postDate = item?.postDate
            break

          case 'glossaryPage_glossaryPage_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            postDate = item?.postDate
            break

          case 'search_search_Entry':
            image = item?.relatedContentMeta?.[0]?.metaImage?.[0]
            title = item?.relatedContentMeta?.[0]?.metaTitle
            excerpt = item?.relatedContentMeta?.[0]?.metaexcerpt
            uri = item?.uri
            postDate = item?.postDate
            break
          }

          return (
            uri && <CardDesignItem key={index} href={uri ? `/${uri}` : '#'}>
              {image?.__typename === 'images_Asset' && (
                <CardDesignItem image src={image?.url} alt={image.alt} layout="fill" objectFit="cover" objectPosition="center" />
              )}
              <CardDesignItem date>{new Date(postDate).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
              <CardDesignItem title>{title ? truncate(title, 75) : 'Untitled'}</CardDesignItem>
              {excerpt && <CardDesignItem description>{truncate(excerpt, 200)}</CardDesignItem>}
            </CardDesignItem>
          )
        })}
      </CardDesignCarousel>
    </>
  )
}

export const RELATED_ARTICLES_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment RelatedArticlesHomeFragment on EntryInterface {
    ... on glossaryPage_glossaryPage_Entry {
      id
      uri
      postDate
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_listing_Entry {
      id
      uri
      title
      postDate
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_detail_Entry {
      id
      uri
      title
      postDate
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ... on products_pages_product_fcp_Entry {
      id
      uri
      title
      postDate
      secondaryPageBanner {
        ... on secondaryPageBanner_BlockType {
          secondaryBannerImage {
            ...ImageFragment
          }
        }
      }
      metaExcerpt
    }
    ...on search_search_Entry {
      id
      uri
      postDate
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on faqLandingPage_faqLandingPage_Entry {
      id
      uri
      postDate
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on newsLandingPage_newsLandingPage_Entry {
      id
      uri
      postDate
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on news_newsEntryType_Entry {
      id
      uri
      title
      postDate
      newsArticleDescription
      newsDetailBanner {
        ... on newsDetailBanner_BlockType {
          bannerImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`
